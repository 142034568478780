import { useParams } from '@solidjs/router';
import { For, Show, Switch, Match, createMemo, useContext, createSignal } from 'solid-js';
import {
	Avatar,
	Button,
	DialogContent,
	DialogAction,
	DialogActions,
	Dialog,
	Errors,
	Form,
	Link,
	DialogTrigger,
} from '@troon/ui';
import { IconCalendar, IconSquareWarning, IconTrashFull, IconUsers } from '@troon/icons';
import { Title } from '@solidjs/meta';
import { useTrackEvent } from '@troon/analytics';
import { ReservationState, ReservationUserState, gql, mutationAction, useMutation } from '../../../graphql';
import { Content } from '../../../components/content';
import { Grid, GridMain, GridSidebar } from '../../../components/layouts/grid';
import { PaymentInfo } from '../../../components/payment-info';
import { CancellationPolicy } from '../../../components/cancellation-policy';
import { InvitePlayers } from '../../../partials/invite-players';
import { getConfigValue } from '../../../modules/config';
import { useUser } from '../../../providers/user';
import { ReservationCtx } from '../../../providers/reservation';
import { FacilityLocation } from '../../../components/facility/location';
import { FacilityReservationHeader } from '../../../components/facility/reservation-header';
import type {
	CalendarDayTime,
	Currency,
	Reservation as IReservation,
	ReservationHeaderFragment,
	ReservationUser,
} from '../../../graphql';

export default function Reservation() {
	const params = useParams();
	const user = useUser();
	const data = useContext(ReservationCtx);
	const track = useTrackEvent();

	const acceptAction = useMutation(handleAccept);
	const rejectAction = useMutation(handleReject);
	const leaveAction = useMutation(handleLeave);
	const removeAction = useMutation(handleRemove);

	const [showCancelConfirm, setShowCancelConfirm] = createSignal(false);
	const [showInvite, setShowInvite] = createSignal(false);

	const reservationUser = createMemo(() => {
		return data()?.reservation.users.find((resUser) => resUser.user?.id === user()?.me.id);
	});

	const canModify = createMemo(() => {
		return (
			data()?.reservation.ownerId === user()?.me.id &&
			data()?.reservation.state !== ReservationState.Cancelled &&
			!data()?.reservation.isPastStartTime
		);
	});

	return (
		<Content>
			<Show when={data()?.reservation}>
				{(reservation) => (
					<>
						<Title>
							Tee time reservation at {reservation().facility.name ?? reservation().course.name ?? ''} | Troon
						</Title>

						<FacilityReservationHeader
							bordered
							reservation={() => reservation() as unknown as ReservationHeaderFragment}
							user={() =>
								reservation().users.find((u) => u.user?.id && u.user?.id === user()?.me.id) as
									| ReservationUser
									| undefined
							}
						>
							<div class="flex flex-row items-stretch justify-end gap-4">
								<Switch>
									<Match when={reservation().isPastStartTime || reservation().state === ReservationState.Cancelled}>
										{null}
									</Match>
									<Match when={reservationUser()?.state === ReservationUserState.Invited}>
										<Form action={acceptAction} document={acceptInvitationMutation} method="post">
											<input type="hidden" name="reservationUserId" value={reservationUser()?.id} />
											<Errors />
											<Button type="submit">Accept invitation</Button>
										</Form>
										<Form action={rejectAction} document={rejectInvitationMutation} method="post">
											<input type="hidden" name="reservationUserId" value={reservationUser()?.id} />
											<Errors />
											<Button appearance="secondary" action="danger" type="submit">
												Reject invitation
											</Button>
										</Form>
									</Match>
									<Match when={true}>
										<Show
											when={reservation() && canModify()}
											fallback={
												<p class="px-8 text-center">Please check in at the pro shop when you arrive at the course.</p>
											}
										>
											<Dialog key="reservation-cancel" open={showCancelConfirm()} onOpenChange={setShowCancelConfirm}>
												<DialogTrigger appearance="secondary" action="danger" class="shrink md:grow-0">
													Cancel tee time
												</DialogTrigger>
												<DialogContent header="Cancel reservation" headerLevel="h2">
													<CancelReservation
														reservation={reservation() as IReservation}
														onSuccess={() => {
															setShowCancelConfirm(false);
														}}
														onCancel={() => {
															setShowCancelConfirm(false);
														}}
													/>
												</DialogContent>
											</Dialog>
										</Show>
									</Match>
								</Switch>
								<Dialog key="reservation-calendar-event">
									<DialogTrigger appearance="secondary" class="shrink grow-0">
										<IconCalendar /> <span class="sr-only">Calendar</span>
									</DialogTrigger>
									<DialogContent header="Add to calendar" headerLevel="h2">
										<div class="grid grid-cols-2 gap-4">
											<For each={Object.entries(reservation().calendarEvent ?? {})}>
												{([key, href]) => (
													<Button
														appearance={calendarImage[key] ? 'secondary' : 'primary'}
														as={Link}
														href={href}
														rel="noopener noreferer"
														onClick={() => {
															track('addToCalendar', { calendarType: key });
														}}
														class={calendarImage[key] ? 'flex flex-col items-center gap-2 normal-case' : 'col-span-2'}
													>
														<Show when={calendarImage[key]}>
															{(src) => <img width="84" height="84" src={src()} alt="" />}
														</Show>
														{calendarTitle[key] ?? ''}
													</Button>
												)}
											</For>
										</div>
									</DialogContent>
								</Dialog>
							</div>
						</FacilityReservationHeader>

						<Grid>
							<GridMain class="flex flex-col gap-4">
								<div class="flex flex-col gap-6">
									<h2 class="text-xl font-semibold">Who’s playing?</h2>
									<p>
										Share reservation details with your group to keep everyone in the loop. We’ll notify you when they
										accept.
									</p>

									<Show
										when={
											canModify() &&
											(reservation().users.filter((user) => user.state === ReservationUserState.Empty).length ?? 0) >= 1
										}
									>
										<Button class="shrink grow-0 self-start" appearance="secondary" onClick={() => setShowInvite(true)}>
											<IconUsers /> Invite your group
										</Button>

										<Dialog key="reservation-invite-players" open={showInvite()} onOpenChange={setShowInvite}>
											<DialogContent header="Invite players" headerLevel="h3">
												<InvitePlayers
													reservationId={reservation().id}
													slots={reservation()
														.users.filter((user) => user.state === ReservationUserState.Empty)
														.map(({ id }) => id)}
													shareUrl={`https://${getConfigValue('HOST')}/reservation/${reservation().id}/join/${reservation().shareInviteId}`}
												/>
											</DialogContent>
										</Dialog>
									</Show>

									<h3 class="sr-only">Players</h3>

									<ul class="grid grid-cols-1 gap-4 md:grid-cols-2">
										<For
											each={reservation().users.sort((a, b) => {
												if (a.user?.id === reservation().ownerId || b.state === ReservationUserState.Empty) {
													return -1;
												}
												if (b.user?.id === reservation().ownerId || a.state === ReservationUserState.Empty) {
													return 1;
												}
												return sort(
													`${a.user?.firstName ?? ''} ${a.user?.lastName ?? ''}`,
													`${b.user?.firstName ?? ''} ${b.user?.lastName ?? ''}`,
												);
											})}
										>
											{(reservationUser) => (
												<li class="flex items-center gap-x-4 overflow-hidden rounded border border-neutral-500 p-4 pe-1">
													<Avatar
														class="size-12 shrink-0 grow-0 rounded-full bg-brand text-brand-100"
														firstName={reservationUser.user?.firstName ?? ''}
														lastName={reservationUser.user?.lastName ?? ''}
													/>
													<div class="flex shrink grow flex-col overflow-hidden">
														<span class="w-full overflow-hidden text-ellipsis text-nowrap">
															{reservationUser.user
																? `${reservationUser.user.firstName} ${reservationUser.user.lastName}`
																: 'Guest'}
														</span>
														<span class="text-sm">
															<Switch>
																<Match when={reservationUser.isCheckedIn}>Checked in</Match>
																<Match when={reservationUser.user?.id === reservation().ownerId}>Host</Match>
																<Match when={true}>{reservationStateToText[reservationUser.state]}</Match>
															</Switch>
														</span>
													</div>
													<div class="flex grow-0 flex-row items-center gap-2 justify-self-end">
														<Switch>
															<Match
																when={
																	reservationUser.user?.id !== reservation().ownerId &&
																	reservationUser.state !== ReservationUserState.Empty &&
																	canModify()
																}
															>
																<Dialog key="reservation-remove-user">
																	<DialogTrigger class="text-red-500" type="submit" appearance="transparent-current">
																		<IconTrashFull />
																		<span class="sr-only">Remove from reservation</span>
																	</DialogTrigger>
																	<DialogContent header="Remove from reservation" headerLevel="h3">
																		<Form action={removeAction} document={removeUserMutation} method="post">
																			<input type="hidden" name="userId" value={user()?.me.id} />
																			<input type="hidden" name="reservationUserId" value={reservationUser.id} />
																			<p>
																				Are you sure you want to remove {reservationUser.user?.firstName}{' '}
																				{reservationUser.user?.lastName}?
																			</p>
																			<Errors />
																			<DialogActions>
																				<Button action="danger" type="submit">
																					Remove from reservation
																				</Button>
																			</DialogActions>
																		</Form>
																	</DialogContent>
																</Dialog>
															</Match>
															<Match when={reservationUser.state === ReservationUserState.Empty && canModify()}>
																<Button
																	class="shrink grow-0 font-normal normal-case"
																	appearance="transparent"
																	onClick={() => setShowInvite(true)}
																>
																	+ Invite
																</Button>
															</Match>
														</Switch>
													</div>
												</li>
											)}
										</For>
									</ul>

									<hr class="my-4 border-0 border-t border-neutral-500" />

									<h2 class="text-xl font-semibold">Facility info</h2>
									<Show when={reservation().facility}>{(facility) => <FacilityLocation facility={facility()} />}</Show>
								</div>
							</GridMain>

							<GridSidebar>
								<div class="sticky top-24 flex flex-col gap-y-4 rounded border border-neutral-500 p-4">
									<div>
										<PaymentInfo
											receipt={data()?.reservationPaymentInfo.receipt}
											supportsTroonRewards={reservation().course.supportsTroonRewards}
										/>
									</div>

									<div class="flex flex-col gap-y-2">
										<h3 class="text-lg font-semibold">
											<IconSquareWarning class="text-brand" /> Cancellation policy
										</h3>
										<p>
											<CancellationPolicy
												cancelFee={reservation().cancelFee as Currency}
												cancelDayTime={reservation().cancelDayTime as CalendarDayTime}
											/>
										</p>
									</div>

									<Show when={reservation()?.isCancelWindow}>
										<Switch>
											<Match when={canModify()}>
												<Button
													disabled={!reservation().isCancelWindow}
													appearance="secondary"
													action="danger"
													onClick={() => setShowCancelConfirm(true)}
												>
													Cancel tee time
												</Button>
											</Match>
											<Match when={reservation()?.state === ReservationState.Active}>
												<Dialog key="reservation-leave">
													<DialogTrigger
														action="danger"
														disabled={!reservation().isCancelWindow}
														appearance="secondary"
													>
														Leave reservation
													</DialogTrigger>
													<DialogContent header="Leave reservation" headerLevel="h2">
														<Form action={leaveAction} document={leaveReservationMutation} method="post">
															<input type="hidden" name="reservationUserId" value={reservationUser()?.id} />
															<input type="hidden" name="reservationId" value={params.reservationId} />
															<p>Are you sure you want to leave this reservation?</p>
															<Errors />

															<DialogActions>
																<DialogAction action="danger" type="submit">
																	Leave reservation
																</DialogAction>
															</DialogActions>
														</Form>
													</DialogContent>
												</Dialog>
											</Match>
										</Switch>
									</Show>
								</div>
							</GridSidebar>
						</Grid>
					</>
				)}
			</Show>
		</Content>
	);
}

function CancelReservation(props: { reservation: IReservation; onSuccess: () => void; onCancel: () => void }) {
	const handleCancel = useMutation(
		mutationAction(cancelReservationMutation, {
			revalidates: ['reservationInformation'],
			toast: 'Your tee time has been cancelled.',
			onSuccess: props.onSuccess,
			track: {
				event: 'cancelReservation',
				data: { id: props.reservation.id },
			},
		}),
	);

	return (
		<Switch
			fallback={
				<div class="flex flex-col gap-8">
					<p>
						It is too late to cancel this tee time according to the cancellation policy. Please call the golf shop
						instead
						<Show when={props.reservation.facility.metadata?.phone}>
							{(phone) => (
								<>
									{' '}
									at <span class="font-semibold">{phone()}</span>
								</>
							)}
						</Show>
						.
					</p>
					<Button as={Link} href={`tel:${props.reservation.facility.metadata?.phone}`}>
						Call golf shop
					</Button>
				</div>
			}
		>
			<Match when={props.reservation.isCancelWindow}>
				<Form action={handleCancel} document={cancelReservationMutation} method="post">
					<p>Are you sure you want to cancel this reservation? There is no guarantee you’ll be able to rebook.</p>
					<Show when={props.reservation.cancelDayTime}>
						<p>
							<CancellationPolicy
								cancelFee={props.reservation.cancelFee as Currency}
								cancelDayTime={props.reservation.cancelDayTime as CalendarDayTime}
							/>
						</p>
					</Show>
					<input type="hidden" name="id" value={props.reservation.id} />
					<Errors />
					<DialogActions>
						<DialogAction type="submit" action="danger">
							Confirm cancellation
						</DialogAction>
						<DialogAction appearance="transparent" type="button" onClick={props.onCancel}>
							Nevermind
						</DialogAction>
					</DialogActions>
				</Form>
			</Match>
		</Switch>
	);
}

const reservationStateToText: Record<ReservationUserState, string | undefined> = {
	[ReservationUserState.Invited]: 'Invited',
	[ReservationUserState.Accepted]: 'Accepted',
	[ReservationUserState.Cancelled]: 'Cancelled',
	[ReservationUserState.Empty]: undefined,
};

const sort = new Intl.Collator('en').compare;

const cancelReservationMutation = gql(`
mutation cancelReservation($id: String!) {
	cancelReservation(id: $id) {
		id
	}
}`);

const removeUserMutation = gql(`
mutation removeUserFromReservation($reservationUserId: String!) {
	removeUserFromReservation(reservationUserId: $reservationUserId) {
		id
	}
}`);

const handleRemove = mutationAction(removeUserMutation, {
	revalidates: ['reservationInformation'],
	track: {
		event: 'removeUser',
		transform: (data) => ({
			userId: data.get('userId') as string,
			reservationUserId: data.get('reservationUserId') as string,
		}),
	},
});

const acceptInvitationMutation = gql(`
mutation acceptReservationInvite($reservationUserId: String!) {
	acceptReservationInvite(reservationUserId: $reservationUserId) {
		id
	}
}`);

const handleAccept = mutationAction(acceptInvitationMutation, {
	revalidates: ['reservationInformation'],
	track: {
		event: 'acceptInvitation',
		transform: (data) => ({ reservationUserId: data.get('reservationUserId') as string }),
	},
});

const rejectInvitationMutation = gql(`
	mutation rejectReservationInvite($reservationUserId: String!) {
		rejectReservationInvite(reservationUserId: $reservationUserId) {
			ok
		}
	}`);

const handleReject = mutationAction(rejectInvitationMutation, {
	redirect: '/',
	toast: 'You have declined this invitation.',
	track: {
		event: 'rejectInvitation',
		transform: (data) => ({ reservationId: data.get('reservationId') as string }),
	},
});

const leaveReservationMutation = gql(`
mutation leaveReservation($reservationUserId: String!) {
	leaveReservation(reservationUserId: $reservationUserId) {
		ok
	}
}`);

const handleLeave = mutationAction(leaveReservationMutation, {
	redirect: '/',
	toast: 'You have left this reservation.',
	track: {
		event: 'leaveReservation',
		transform: (data) => ({ reservationId: data.get('reservationId') as string }),
	},
});

const calendarImage: Record<string, string> = {
	google: '/assets/calendar/google-calendar.svg',
	office365: '/assets/calendar/office-365.svg',
	outlook: '/assets/calendar/outlook.svg',
	yahoo: '/assets/calendar/yahoo.svg',
};

const calendarTitle: Record<string, string> = {
	google: 'Google',
	office365: 'Office 365',
	outlook: 'Outlook',
	yahoo: 'Yahoo',
	ics: 'Download .ics',
};
